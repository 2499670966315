<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Danh sách phí</div>
                    <div class="dialog-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
                <div class="dialog-search">
                    <md-field>
                        <label for="categoryId"></label>
                        <md-select v-model="search.categoryId" name="categoryId" id="categoryId">
                            <md-option value="0">Tất cả loại phí</md-option>
                            <md-option v-for="item in categories" :key="item.id" :value="item.id">{{item.categoryName}}</md-option>
                        </md-select>
                        <md-icon>search</md-icon>
                    </md-field>
                    <md-field>
                        <md-input v-model="search.name" placeholder="Tìm theo tên"></md-input>
                        <md-icon>search</md-icon>
                    </md-field>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th style="width: 350px;">Mã phí</th>
                                        <th style="width: 400px;">Tên phí</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && pager.totalItems > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td><a @click="selected(item)">{{item.feeCode}}</a></td>
                                        <td><a @click="selected(item)">{{item.feeName}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer" v-if="pager.totalPages > 0">
                    <div class="dialog-paging">
                        <div v-if="pager.totalPages > 0">
                            <v-pagination v-model="search.pageIndex"
                            :page-count="pager.totalPages"
                            @change="onChange"></v-pagination>
                        </div>
                    </div>
                    <div>|</div>
                    <div v-if="pager.totalPages > 0" class="dialog-data-info">Xem <span>{{pager.startIndex}}-{{pager.endIndex}}/{{pager.totalItems}}</span> bản ghi</div>
                    <div>|</div>
                    <div class="dialog-paging-row-of-page">Rows/page: <a v-for="item in rowPerPageOptions" :key="item.id" :class="{'current-row-of-page': search.pageSize == item.id}" @click="changeRowPerPage(item.id)">{{item.text}}</a></div>
                    <div>|</div>
                    <div class="dialog-refresh"><a @click="refresh()">Làm tươi</a></div>
                </div>
            </md-content>
        </md-dialog>
        <crudForm ref="crudForm" @close="closeCrud"/>
    </div>
</template>

<script>
    import feeService from '../../api/feeService'; 
    import messageBox from '../../utils/messageBox'; 
    import common from '../../mixins';
    import vPagination from '../Pagination.vue';
    import crudForm from '../../views/transport-manage/fee/Crud.vue'
    import categoryService from '../../api/categoryService';

    export default {
        components: {
            vPagination,
            crudForm
        },
        data: () => ({
            showDialog: false,
            ids: [],
            data: [],
            pager: { totalPages: 1 }, 
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, type: 1, categoryId: 0, code: '', name: '' },
            rowPerPageOptions: common.rowPerPagePopupOptions,
            categories: [],
            categoryType: common.categoryType.fee
        }),
        created() {
            
        },

        methods: {
            getCategories(){
                categoryService.getAll(this.categoryType).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            add(){
                this.$refs.crudForm.open(0);
            },

            closeCrud(action){
                this.refresh();
                if(!action){
                    this.$refs.crudForm.close();
                }
            },

            selected(item){
                this.$emit('close', item);
            },

            onChange: function () {
                this.getData();
            },

            open(type){
                if(type !== undefined){
                    this.search.type = type;
                }
                if(type == 0){
                    this.search.type = 2;
                }
                if(this.search.type == 2){
                    this.categoryType = 6;
                }
                this.getCategories();
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            changeRowPerPage(pageSize){
                this.search.pageSize = pageSize;
                this.search.name = '';
                this.search.code = '';
                this.getData();
            },

            refresh() {
                this.search.pageIndex = 1;
                this.search.name = '';
                this.search.code = '';
                this.getData();
            },

            getData(){
                this.loadding = true;
                feeService.getFees(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items; 
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            'search.pageIndex': function () { 
                this.getData(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.categoryId': function () {
                this.search.pageIndex = 1;
                this.getData();
            },
            'search.name': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            } 
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 800px;
        height: 455px;
        max-width: 800px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
</style>